var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rpid
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { attrs: { flat: "", color: "transparent" } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("mdi-server"),
                  ]),
                  _c("div", { staticClass: "text-h6 secondary--text" }, [
                    _vm._v("Large file storage"),
                  ]),
                  _c("v-spacer"),
                  _vm.canModify
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.availableStorageTierList.length === 0,
                            color: "primary",
                          },
                          on: { click: _vm.openStorageDialog },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("add"),
                          ]),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("add"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.activeStorageList.length > 0
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c("h4", { staticClass: "mb-2" }, [_vm._v("Active")]),
                      _c("storage-card-list", {
                        attrs: {
                          items: _vm.activeStorageList,
                          "can-modify":
                            _vm.availableStorageTierList.length > 0 &&
                            _vm.canModify,
                          selectedRP: _vm.selectedRP,
                        },
                        on: {
                          storageSelected: _vm.selectStorage,
                          showMountDialog: _vm.fetchMounts,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cancelledStorageList.length > 0
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c("h4", { staticClass: "mb-2" }, [_vm._v("Cancelled")]),
                      _c("storage-card-list", {
                        attrs: {
                          items: _vm.cancelledStorageList,
                          "can-modify":
                            _vm.availableStorageTierList.length > 0 &&
                            _vm.canModify,
                        },
                        on: { showMountDialog: _vm.fetchMounts },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.purchasedStorages.length === 0
                ? _c(
                    "v-container",
                    [
                      _vm.fetchingPurchasedStorages
                        ? _c("dashboard-loader")
                        : _vm._e(),
                      !_vm.fetchingPurchasedStorages && _vm.hasError
                        ? _c("dashboard-error")
                        : _vm.purchasedStorages.length === 0
                        ? _c("dashboard-empty", [
                            _vm._v(
                              "No additional storage have been created yet."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.availableStorageTierList.length > 0
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: _vm.dialogMaxWidth },
                      model: {
                        value: _vm.addStorageDialog,
                        callback: function ($$v) {
                          _vm.addStorageDialog = $$v
                        },
                        expression: "addStorageDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedStorage ? "Edit" : "Add new"
                                  ) +
                                  " storage "
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    href: "https://docs.nuvolos.cloud/features/file-system-and-storage/large-file-storage",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("help")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mb-6" }),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "addStorageForm",
                                  model: {
                                    value: _vm.addStorageForm,
                                    callback: function ($$v) {
                                      _vm.addStorageForm = $$v
                                    },
                                    expression: "addStorageForm",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      outlined: "",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.unique,
                                      ],
                                      label: "Type a name for your storage",
                                      hint: "Please select a unique name, using letters and numbers",
                                      autofocus: !_vm.selectedStorage,
                                    },
                                    model: {
                                      value: _vm.storageName,
                                      callback: function ($$v) {
                                        _vm.storageName = $$v
                                      },
                                      expression: "storageName",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      label: "Please select size",
                                      outlined: "",
                                      min: _vm.minSize,
                                      max: _vm.selectedTierObject?.max_size_gib,
                                      step: "10",
                                      "persistent-hint": "",
                                      type: "number",
                                      rules: [
                                        _vm.rules.integer,
                                        _vm.rules.required,
                                        _vm.rules.gte(_vm.minSize),
                                        _vm.rules.lte(
                                          _vm.selectedTierObject?.max_size_gib
                                        ),
                                        _vm.rules.increment,
                                      ],
                                      hint: `AC ${_vm.availableStorageTierList[0].unit_price.toFixed(
                                        2
                                      )} / GiB / year`,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-outer",
                                          fn: function () {
                                            return [_vm._v("GiB")]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3010546667
                                    ),
                                    model: {
                                      value: _vm.storageSize,
                                      callback: function ($$v) {
                                        _vm.storageSize = $$v
                                      },
                                      expression: "storageSize",
                                    },
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: { text: "", type: "info" },
                                            },
                                            [
                                              _vm._v(
                                                " This storage can be attached in " +
                                                  _vm._s(_vm.mappedSpaces) +
                                                  " space" +
                                                  _vm._s(
                                                    _vm.mappedSpaces > 1
                                                      ? "s"
                                                      : ""
                                                  ) +
                                                  ". "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "my-3" }),
                                  _c(
                                    "v-row",
                                    { attrs: { align: "end" } },
                                    [
                                      _c("v-col", [
                                        _c(
                                          "div",
                                          { staticClass: "text-body-1" },
                                          [_vm._v("Total price")]
                                        ),
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _c(
                                          "h2",
                                          { staticClass: "text-right text-h4" },
                                          [
                                            _vm._v(
                                              " AC " +
                                                _vm._s(_vm.totalPrice) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "text-body-1" },
                                              [_vm._v("/ year")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "mt-3" }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _vm.selectedStorage
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error", text: "" },
                                      on: { click: _vm.showCancelDialog },
                                    },
                                    [_vm._v("Cancel storage")]
                                  )
                                : _vm._e(),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.closeDialog },
                                },
                                [_vm._v("Back")]
                              ),
                              _vm.selectedStorage
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.addStorageForm,
                                        loading: _vm.editingStorage,
                                      },
                                      on: { click: _vm.showConfirmEditDialog },
                                    },
                                    [_vm._v(" Modify ")]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.addStorageForm,
                                        loading: _vm.addingStorage,
                                      },
                                      on: { click: _vm.showConfirmAddDialog },
                                    },
                                    [_vm._v("Add")]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.mountData
                ? _c(
                    "v-dialog",
                    {
                      attrs: { width: _vm.dialogMaxWidth },
                      model: {
                        value: _vm.mountDataDialog,
                        callback: function ($$v) {
                          _vm.mountDataDialog = $$v
                        },
                        expression: "mountDataDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [_vm._v("Usage info")]),
                          _c(
                            "v-card-text",
                            [
                              _vm.spaceDataError
                                ? _c("dashboard-error")
                                : _vm.mountData.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "This storage is attached in the following spaces:"
                                        ),
                                      ]),
                                      _c("v-divider"),
                                    ],
                                    1
                                  )
                                : _c("dashboard-empty", [
                                    _vm._v(
                                      "This storage is not attached to any spaces yet."
                                    ),
                                  ]),
                              _vm.mountData.length > 0
                                ? _c(
                                    "v-expansion-panels",
                                    { attrs: { accordion: "", flat: "" } },
                                    _vm._l(_vm.mountData, function (space) {
                                      return _c(
                                        "v-expansion-panel",
                                        {
                                          key: space.sid,
                                          attrs: {
                                            readonly:
                                              space.space_admins.length === 0,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  space.space_admins.length ===
                                                  0
                                                    ? {
                                                        key: "actions",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  disabled: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Space has no admins"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(space.long_id) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "v-sheet",
                                                {
                                                  attrs: {
                                                    rounded: "",
                                                    color: "grey lighten-4",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    {
                                                      attrs: {
                                                        subheader: "",
                                                        "two-line": "",
                                                        color: "transparent",
                                                      },
                                                    },
                                                    [
                                                      _c("v-subheader", [
                                                        _vm._v("Space admins"),
                                                      ]),
                                                      _vm._l(
                                                        space.space_admins,
                                                        function (admin) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key: admin.email,
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          admin.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          admin.email
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "copy-to-clipboard",
                                                                    {
                                                                      attrs: {
                                                                        textToCopy:
                                                                          admin.email,
                                                                        buttonClass:
                                                                          "caption font-weight-bold",
                                                                        buttonColor:
                                                                          "secondary",
                                                                        tooltip:
                                                                          "Copy email to clipboard",
                                                                        iconButton: true,
                                                                        isSmall: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.mountDataDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }