var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.items, function (storage) {
      return _c(
        "v-col",
        {
          key: storage.afsid,
          attrs: { md: "6", lg: "4", xl: "3", cols: "12" },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "", color: "grey lighten-3" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        "two-line": "",
                        color: "transparent",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            { staticClass: "my-0" },
                            [
                              !storage.cancellation_timestamp
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary lighten-1",
                                      attrs: { dark: "" },
                                    },
                                    [_vm._v("check_circle")]
                                  )
                                : _c(
                                    "v-icon",
                                    {
                                      staticClass: "grey lighten-1",
                                      attrs: { dark: "" },
                                    },
                                    [_vm._v("cancel")]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0" },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(storage.long_id)),
                              ]),
                              !storage.cancellation_timestamp
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " Next billing date: " +
                                        _vm._s(
                                          _vm._f("dateToHuman")(
                                            storage.next_billing_date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("v-list-item-subtitle", [
                                    _vm._v(
                                      "Available until: " +
                                        _vm._s(
                                          _vm._f("dateToHuman")(
                                            storage.last_availability_date
                                          )
                                        )
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "text-center" },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _c("div", { staticClass: "text-h5 secondary--text" }, [
                          _vm._v(_vm._s(storage.quota_gib)),
                        ]),
                        _c("label", { staticClass: "text--secondary" }, [
                          _vm._v("Quota (GiB)"),
                        ]),
                      ]),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _c("div", { staticClass: "text-h5 secondary--text" }, [
                          _vm._v(
                            " " + _vm._s(storage.mounted_count || 0) + " "
                          ),
                        ]),
                        _c(
                          "label",
                          { staticClass: "text--secondary" },
                          [
                            _vm.selectedRP?.is_rp_manager
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "showMountDialog",
                                          storage.afsid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Spaces using "),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          color: "primary",
                                        },
                                      },
                                      [_vm._v("mdi-arrow-top-right-thick")]
                                    ),
                                  ],
                                  1
                                )
                              : [_vm._v("Spaces using")],
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "px-14 pt-4", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-progress-linear",
                                          _vm._g(
                                            {
                                              staticClass: "my-2",
                                              attrs: {
                                                value:
                                                  _vm.calculateUsage(storage),
                                                rounded: "",
                                                color: _vm.usageColor(storage),
                                                height: "8",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.humanFileSize(storage.storage_used)
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c("label", { staticClass: "text--secondary" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.calculateUsage(storage)) + "% used"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectedRP?.is_rp_manager
                ? _c(
                    "v-card-actions",
                    { staticClass: "align-start" },
                    [
                      _c("v-spacer"),
                      !storage.cancellation_timestamp
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "primary",
                                disabled: !_vm.canModify,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("storageSelected", storage)
                                },
                              },
                            },
                            [_vm._v(" Modify ")]
                          )
                        : _c("v-btn", { attrs: { text: "", disabled: "" } }, [
                            _vm._v("Cancelled"),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }